/* You can add global styles to this file, and also import other style files */

@import "assets/plugins/switchery/switchery.min.css";
@import "assets/plugins/jquery-circliful/css/jquery.circliful.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/core.css";
@import "assets/css/icons.css";
@import "assets/css/components.css";
@import "assets/css/pages.css";
@import "assets/css/menu.css";
@import "assets/css/responsive.css";
@import "assets/plugins/footable/css/footable.core.css";
@import "~ngx-toastr/toastr.css";
/* @import "assets/css/fixes.css"; */

@import "assets/css/preloaders.css";
@import "~sweetalert2/dist/sweetalert2.min.css";

#wrapper.left-bar-enabled .left-bar {
    left: 0 !important;
    overflow: hidden;
}

.side-bar.left-bar {
    top: 0px !important;
}

:root {
    /*************************** Page-wide variables ****************************/
    --primary-color: #4ac6ff;
    --main-bg-color: #ffffff;
    --hover-color: #cacaca;
    /* --convo-bg-color: #ecf2f5; */
    --convo-bg-color: #ecf0f5;
    --disable: #8a8f9c;
    --border-color: 1px solid #eeeeee;
    --label-font-color: #8a8f9c;
    --span-float-right: right;
    --date-color: #59626a;
    --dash: #dddddd;
    --selected: #555252;
    --h2-font: #383737;
    --top-bar-back-color: #07a3e1;
    /*--side bar Main styels */
    /*----avatar*/
    --avatar-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.11), 0 6px 6px rgba(0, 0, 0, 0.15);
    /* inner-workCar-shadow */
    --workcard-shadow: inset -1px -1px 12px 0px rgba(0, 0, 0, 0.1);
    --text-box-background: #f5f5f5;
    /* ----states */
    --active-state: #388e3c;
    --confirmed-state: #ff2c00;
    --pending-state: #f57c00;
    --declined-state: #607d8b;
    --done-state: #ffaa00;
    /* sidebars */
    --tabs-color: #3f3f3f;
    --tab-active: #07a3e1;
    /* ---layouting co-ordinates*/
    --zero: 0px;
    --one: 1px;
    --two: 2px;
    /* scale for 1.2 */
    --ms-small-1: 1rem;
    --ms-small-2: 1.2rem;
    --ms-small-3: 1.44rem;
    --ms-small-4: 1.728rem;
    --ms-small-5: 2.074rem;
    --ms-small-6: 2.488rem;
    /* scale for 1.33 */
    --ms-large-1: 1rem;
    --ms-large-2: 1.333rem;
    --ms-large-3: 1.777rem;
    --ms-large-4: 2.369rem;
    --ms-large-5: 3.157rem;
    --ms-large-6: 4.209rem;
    /* Number of columns to show. */
    --grid-columns: 3;
    /* Base spacing unit. */
    --spacing-unit: 6px;
    /* Margin size. No unit, because it's a multiple of the spacing unit. */
    --margins: 2;
    /* The size of the margin around the card grid. */
    --margin-size: (var(--margins) * 2);
    /* How much internal padding each cell should have */
    --cell-padding: (4 * var(--spacing-unit));
    /* How big the space between cells should be */
    --grid-gutter: (var(--margins) * var(--spacing-unit));
    /* How big the space should be around the grid */
    --grid-margin: (var(--margin-size) * var(--spacing-unit));
    /* Calculated cell margin */
    --cell-margin: (var(--grid-gutter) / 2);
}

.no-margin {
    margin: var(--zero);
}

.no-padding {
    padding: var(--zero);
}

.margin-r-2x {
    margin-right: calc((var(--two) * 4));
}

.c-active-border {
    border: 1px solid var(--active-state) !important;
}

.c-pending-border {
    border: 1px solid var(--pending-state) !important;
}

.c-closed-border {
    border: 1px solid var(--declined-state) !important;
}

.c-active-hover {
    background: #cde3ce !important;
}

.c-pending-hover {
    background: #f57c0045 !important;
}

.c-closed-hover {
    background: #39495145 !important;
}

.ng2-opd-popup-content-main[_ngcontent-c6] {
    top: 2% !important;
}

.profile {
    margin: 22px 0 10px;
}

.inline-btn {
    display: inline;
    border: 0;
    text-decoration: none;
    margin-right: 5px;
}

/* Buttons */
.btn-link {
    text-decoration: none !important;
}

btn-link:hover {
    background-color: var(--main-bg-color) !important;
    border: 1px solid var(--border-color);
    color: var(--date-color);
}

.hamberger-rightbar {
    padding: 10px;
}

#breakpoints {
    /* border-bottom: 1px solid var(--dash);*/
}

body {
    font-size: 12px !important;
    color: #34495e !important;
}

.p-l-1 {
    padding-left: 1px !important;
}

.p-l-0 {
    padding-left: 0px !important;
}

.p-r-0 {
    padding-right: 0px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.small,
small {
    font-size: 100% !important;
}

.text-primary {
    color: #07a3e1 !important;
}

option:disabled {
    color: #c1c1c1;
    opacity: 0;
}

.wrapper.pop {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    float: left;
    min-height: 100%;
    padding: 100px;
    height: 100vh;
    background-color: #ecf0f5;
}

.wrapper.pop .logo {
    float: left;
    width: 100%;
    position: relative;
    text-align: center;
}

.wrapper.pop h3 {
    float: left;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 20px 0 5px 0;
}

#formContent {
    border-radius: 5px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
    text-align: center;
    float: left;
}

#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    float: left;
}

.wrapper.pop form {
    float: left;
    width: 100%;
    position: relative;
    padding: 20px;
    background: #ffffff;
}

.wrapper.pop h2.inactive {
    color: #cccccc;
}

.wrapper.pop h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
}

.wrapper.pop label {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    margin: 0;
}

.wrapper.pop .login {
    background-color: #07a3e1 !important;
    border-color: #07a3e1 !important;
    border: none;
    width: 100%;
    color: white;
    padding: 5px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: none;
    font-size: 13px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.wrapper.pop input[type="button"]:hover,
.wrapper.pop input[type="submit"]:hover,
.wrapper.pop input[type="reset"]:hover {
    background-color: #39ace7;
}

.wrapper.pop input[type="button"]:active,
.wrapper.pop input[type="submit"]:active,
.wrapper.pop input[type="reset"]:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.wrapper.pop input {
    background: none;
    border: none;
    color: #0d0d0d;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #cccccc !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    margin: 0;
}

.wrapper.pop input:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
}

.wrapper.pop input:placeholder {
    color: #cccccc;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

.underlineHover:hover {
    color: #0d0d0d;
}

.underlineHover:hover:after {
    width: 100%;
}

*:focus {
    outline: none;
}

#icon {
    width: 60%;
}

.modal.fade {
    opacity: 1 !important;
    z-index: 1000000;
}

.modal-backdrop.fade.show {
    opacity: 0.5 !important;
}

.modal-dialog {
    position: fixed;
    width: 400px;
    margin: 10px;
    left: 30%;
    right: 40%;
    top: 25%;
    bottom: 0;
}

@media (min-width:1530px) {
    .add-work-popup-wrapper .modal-dialog {
        width:1000px !important;
    }
    .modal-dialog .modal-body{
        overflow-y: auto;
        max-height: 520px;
    }
}

@media (max-width:1400px) {
    .add-work-popup-wrapper .modal-dialog {
        width:800px !important;
    }
    .modal-dialog .modal-body{
        overflow-y: auto;
        max-height: 427px;
    }
}

.attachment-popup .modal-dialog {
    width:750px !important;
    max-width: 90% !important;
    top: 10%;
}

.attachment-popup .modal-header {
    color: #FFFFFF;
    font-size: 14px;
}


.attachment-popup .modal-header .pull-left {
    margin-right: 15px;
}

.attachment-popup .modal-header .close {
    margin-left: 15px;
    color: #FFFFFF;
}

.attachment-popup .modal-dialog img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 70vh;
}

.modal-content {
    padding: 0 !important;
    border: none !important;
    border-radius: 5px !important;
    overflow: hidden;
}

.modal-header {
    padding: 5px 15px !important;
    background: #07a3e1;
}

.modal-header h4 {
    float: left;
    width: auto !important;
    color: #ffffff;
}

.modal-header button {
    opacity: 1 !important;
}

.modal-header button span {
    color: #ffffff !important;
    opacity: 1 !important;
    line-height: 27px;
}

.modal-body {
    padding: 15px !important;
}

.modal-body .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    font-weight: normal;
}

.modal-body .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.modal-body .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
}

.modal-body .container:hover input ~ .checkmark {
    background-color: #ccc;
}

.modal-body .container input:checked ~ .checkmark {
    background-color: #2196f3;
}

.modal-body .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.modal-body .container input:checked ~ .checkmark:after {
    display: block;
}

.modal-body .container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.modal-footer {
    padding: 15px !important;
    text-align: center;
}

.modal-footer button {
    background: #07a3e1 !important;
    color: #ffffff !important;
    border-radius: 5px;
    display: inline-block;
}

.modal-footer button:last-child {
    background: #efefef !important;
    color: #000000 !important;
}

.modal-backdrop.in {
    z-index: 100000;
}

#addworkModal h5 {
    color: #ffffff;
    line-height: 30px;
}

#addworkModal .modal-dialog {
    width: 600px;
    top: 12%;
}

#addworkModal .modal-header {
    padding: 5px 15px !important;
    background: #07a3e1;
}

#addworkModal .modal-footer {
    padding: 10px 15px !important;
}

#addworkModal .calendar-input {
    float: left;
    width: calc(100% + -44px);
}

#addworkModal .calendar-wrap {
    float: left;
    width: 100%;
}

#addworkModal .calendar-wrap .glyphicon {
    color: #07a3e1;
}

#addworkModal .input-group-append {
    float: left;
    width: 44px;
}

#addworkModal input {
    font-size: 12px;
}

#addworkModal .form-group {
    width: 100%;
    float: left;
}

#addworkModal textarea {
    background-color: #fafafa;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    float: left;
    width: 100%;
    resize: vertical;
    min-height: 150px;
    padding: 6px 12px;
}

#addworkModal .ngb-dp-months {
    padding: 10px;
}

#addworkModal .ngb-dp-weekday {
    font-size: 12px !important;
}

#addworkModal .ngb-dp-day {
    font-size: 12px !important;
}

#addworkModal .ngb-dp-header {
    padding: 10px 0 9px 0;
    background: #07a3e1;
}

#addworkModal .ngb-dp-arrow-btn {
    color: #ffffff;
}

#addworkModal .custom-select {
    border: solid 1px #fff;
    margin: 0 5px;
    font-size: 10px;
    border-radius: 3px;
}

.add-work {
    background: #07a3e1;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 10px;
    border: none;
    margin: 0px 10px 4px 0;
    font-size: 18px;
    float: right;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 1px 1px 9px 1px rgb(0 0 0 / 21%) !important;
}

.btn-group {
    width: 100%;
    padding: 0 0 15px 0;
}

.edit-work {
    background: none;
    color: #07a3e1;
    font-weight: bold;
    padding: 8px 4px;
    border: none;
    border-radius: 5px;
    margin: 0 10px 0 0;
    float: right;
    text-transform: uppercase;
}

.edit-work i {
    color: #07a3e1;
    font-size: 18px;
    position: relative;
    top: 2px;
}

.delete-work {
    background: none;
    color: #07a3e1;
    font-weight: bold;
    padding: 8px 4px;
    border: none;
    border-radius: 5px;
    margin: 0;
    float: right;
    text-transform: uppercase;
}

.delete-work i {
    color: #07a3e1;
    font-size: 18px;
}

.msg-indicater {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
}
@media only screen and (max-width:1400px){
    .sidebar-scrollable {
        height: calc(100vh + -456px) !important;
    }
}
.sidebar-scrollable {
    height: calc(100vh + -331px);
    overflow: auto;
}

.hide-plus-button .sidebar-scrollable {
    max-height: calc(100vh + -235px) !important;
    overflow: auto;
}

.add-work-popup-wrapper .error-msg {
    color: red;
    position: relative;
    top: -13px;
}

.add-work-popup-wrapper .calendar-wrap .glyphicon {
    color: #07a3e1;
}

.add-work-popup-wrapper .calendar-wrap select {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0.5rem;
    font-size: 12px !important;
    height: 2.85rem !important;
    border: solid 1px #dddddd !important;
    border-radius: 5px !important;
}

.ngb-dp-months {
    padding: 0;
    float: left !important;
    width: 100% !important;
}

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
    width: 14.28% !important;
    height: 30px !important;
}

.ngb-dp-weekdays {
    float: left !important;
    width: 100% !important;
    background: #f8f9fa !important;
}

.ngb-dp-weekdays>.ngb-dp-weekday {
    line-height: 28px !important;
    color: #17a2b8 !important;
}

.ngb-dp-weekday {
    font-size: 12px !important;
}

.ngb-dp-day {
    font-size: 12px !important;
}

.ngb-dp-header {
    padding: 9px 0 9px 0 !important;
    background: #f8f9fa !important;
    position: relative;
    float: left;
    width: 100%;
}

.ngb-dp-header button {
    color: #007bff;
}

.ngb-dp-arrow-btn {
    color: #ffffff;
}

.calendar-wrap .dropdown-menu {
    animation: none;
}

.dp-fixed-position.calendar-wrap .dropdown-menu {
    width: 200px;
}
.add-work-popup-wrapper .drop-down {
    width: 100%;
    border: solid 4px #eeeeee !important;
    border-top: solid 1px #eeeeee !important;
    border-left: solid 1px #eeeeee !important;
    background: #ffffff !important;
    height: 200px;
    overflow: auto;
}

.drop-down {
    background-color: #ffffff !important;
    border: solid 2px #EEEEEE !important;
    box-shadow: 0px 1px 6px -5px #000000;
}

.add-work-popup-wrapper .search-results {
    padding: 5px 10px !important;
    color: #000000;
}

.add-work-popup-wrapper .search-results a {
    color: #000000;

}

.add-work-popup-wrapper .search-results:hover * {
    color: #ffffff;
    background: #07a3e1;
}

.add-work-popup-wrapper .form-group {
    width: 100%;
    position: relative;
    float: left;
}

.prev-selected-doc {
    float: left;
    width: 100%;
    position: relative;
    padding: 0;
    top: 0;
    border: solid 1px #EEEEEE;
    border-top: none;
}

.prev-selected-doc li {
    float: left;
    width: 100%;
    position: relative;
    list-style: none;
}

.prev-selected-doc li a {
    float: left;
    width: 100%;
    position: relative;
    padding: 7px 9px 9px 9px;
    background: #fbfbfb;
    color: #000;
    cursor: pointer;
}

.check-grey{
    color: #bababa;
    font-size:10px;
}

.check-blue{
    color: #5587dd;
    font-size:10px;
}

.popover{
   opacity: 1;
   border-radius: 3px;
}

/* scroll customize */
::-webkit-scrollbar-track {
    background-color: #ddd;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ddd;
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #34bdf3;
  }