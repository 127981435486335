header [role="progressbar"][aria-busy="true"] {
    position: absolute;
    top: 0;
    left: -11px;
    padding-top: 8px;
    width: 100%;
    background-color: #4AC6FF;
    -webkit-animation: preloader-background linear 3.5s infinite;
            animation: preloader-background linear 3.5s infinite;
  }
  header [role="progressbar"][aria-busy="true"]::before, header [role="progressbar"][aria-busy="true"]::after {
    display: block;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 0px;
    height: 8px;
    background: #afa;
    -webkit-animation: preloader-front linear 3.5s infinite;
            animation: preloader-front linear 3.5s infinite;
    content: "";
  }
  header [role="progressbar"][aria-busy="true"]::before {
    right: 50%;
  }
  header [role="progressbar"][aria-busy="true"]::after {
    left: 50%;
  }
  
  @-webkit-keyframes preloader-background {
    0%,
    24.9% {
      background-color: #159756;
    }
    25%,
    49.9% {
      background-color: #da4733;
    }
    50%,
    74.9% {
      background-color: #3b78e7;
    }
    75%,
    100% {
      background-color: #fdba2c;
    }
  }
  
  @keyframes preloader-background {
    0%,
    24.9% {
      background-color: #159756;
    }
    25%,
    49.9% {
      background-color: #da4733;
    }
    50%,
    74.9% {
      background-color: #3b78e7;
    }
    75%,
    100% {
      background-color: #fdba2c;
    }
  }
  @-webkit-keyframes preloader-front {
    0% {
      width: 0;
      background-color: #da4733;
    }
    24.9% {
      width: 50%;
      background-color: #da4733;
    }
    25% {
      width: 0;
      background-color: #3b78e7;
    }
    49.9% {
      width: 50%;
      background-color: #3b78e7;
    }
    50% {
      width: 0;
      background-color: #fdba2c;
    }
    74.9% {
      width: 50%;
      background-color: #fdba2c;
    }
    75% {
      width: 0%;
      background-color: #159756;
    }
    100% {
      width: 50%;
      background-color: #159756;
    }
  }
  @keyframes preloader-front {
    0% {
      width: 0;
      background-color: #da4733;
    }
    24.9% {
      width: 50%;
      background-color: #da4733;
    }
    25% {
      width: 0;
      background-color: #3b78e7;
    }
    49.9% {
      width: 50%;
      background-color: #3b78e7;
    }
    50% {
      width: 0;
      background-color: #fdba2c;
    }
    74.9% {
      width: 50%;
      background-color: #fdba2c;
    }
    75% {
      width: 0%;
      background-color: #159756;
    }
    100% {
      width: 50%;
      background-color: #159756;
    }
  }
  * {
    box-sizing: border-box;
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
  }
  
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  header {
    position: absolute;
    bottom: 1%;
    width: 100%;
  }